import React, { useState } from "react";
import "./specs-block.scss";
import { useHistory } from "react-router-dom";

const SpecsBlock = () => {
  const history = useHistory();

  const navigate = () => {
    window.open("https://testnet-app.sunland.finance", "_blank");
  };

  return (
    <div className={"specs-container"}>
      <div className={"specs-image-container"}>
        <img src={"./castle.png"} alt={"castle_img"} />
      </div>

      <div className={"specs-concept"}>
        <h2>Kings and Citizens</h2>

        <ul>
          <li>
            Each land will be available for pre-sale and randomly assigned
            amongst the 177 countries.
          </li>

          <li>
            Use your land to earn tokens. Kings are in charge of the
            administration & infrastructures of their land. The King and his
            Citizens work together to enrich their land and become powerful.
          </li>

          <li>
            Citizens will be able to choose their land and contribute ! Will you
            be a Monk, a Knight or a Farmer ? Each role comes with unique powers
            and abilities.
          </li>

          <li>
            <button className={"cta-mint"} onClick={() => navigate()}>
              Become a land owner!
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpecsBlock;
