import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import Home from "./views/home/home";

const App = () => {
  return (
      <Router>
          <Switch>
              <Route path="/" exact>
                  <Home />
              </Route>
          </Switch>
      </Router>
  )
};

export default App;
