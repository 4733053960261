import React, { useState } from "react";
import "./game-block.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";

const GameBlock = () => {
  const navigateExternal = (location: string) => {
    window.open(location, "_blank");
  };

  return (
    <div className={"specs-container"}>
      <div className={"specs-concept"}>
        <h2>Game</h2>

        <ul>
          <li>
            Sunland is an interactive land game where players can earn tokens by
            playing a character on their land ! Monks, Warriors and Farmers will
            face each other to better their land and earn tokens.
          </li>

          <li>
            Use your acquired tokens to improve your land and expand your
            earnings !
          </li>

          <li>
            <div className={"social-cont"}>
              <div className={"social-badge"}>
                <FontAwesomeIcon icon={faTwitter} />
              </div>

              <div className={"social-badge"}>
                <FontAwesomeIcon icon={faDiscord} />
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className={"game-image-container"}>
        <img src={"./kings_gif.gif"} alt={"kings"} />
      </div>
    </div>
  );
};

export default GameBlock;

/*

                            <a className={"social-badge"} onClick={() => navigateExternal('https://opensea.io/collection/sunland')}>
                                <img src="./opensea.png" alt="opensea_logo" />
                            </a>
 */
