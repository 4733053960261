import React, { useEffect, useState } from "react";
import "./home-block.scss";
import Countdown from "react-countdown-now";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useHistory } from "react-router-dom";
import moment from "moment";

const HomeBlock = () => {
  const [time, setTime] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    const clockStart = moment("01/01/2030", "DD/MM/YYYY");
    const d = new Date(clockStart.toISOString());
    setTime(d.getTime());
  }, []);

  const navigate = () => {
    window.open("https://testnet-app.sunland.finance", "_blank");
  };

  const navigateExternal = (location: string) => {
    window.open(location, "_blank");
  };

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    formatted,
  }: any) => {
    return (
      <>
        <div className={"countdown-item"}>
          <p className={"countdown-value"}>{formatted.days}</p>
          <p className={"countdown-def"}>Days</p>
        </div>
        <span className={"countdown-label"}>:</span>
        <div className={"countdown-item"}>
          <p className={"countdown-value"}>{formatted.hours}</p>
          <p className={"countdown-def"}>Hours</p>
        </div>
        <span className={"countdown-label"}>:</span>
        <div className={"countdown-item"}>
          <p className={"countdown-value"}>{formatted.minutes}</p>
          <p className={"countdown-def"}>Minutes</p>
        </div>
        <span className={"countdown-label"}>:</span>
        <div className={"countdown-item"}>
          <p className={"countdown-value"}>{formatted.seconds}</p>
          <p className={"countdown-def"}>Seconds</p>
        </div>
      </>
    );
  };

  return (
    <div className={"home-block-container"}>
      <div className={"home-block-wrapper"}>
        <h1>Sunland</h1>
        <h2>
          Join a land as a King or Citizen and start to earn money by making
          your land evolve !
        </h2>

        <button className={"cta-mint"} onClick={() => navigate()}>
          Become a land owner!
        </button>

        <div className={"social-cont"}>
          <a className={"social-badge"}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>

          <a className={"social-badge"}>
            <FontAwesomeIcon icon={faDiscord} />
          </a>
        </div>
      </div>

      <div className={"image-block-wrapper"}>
        <img src={"./globe.png"} alt={"globe_img"} />
      </div>
    </div>
  );
};

export default HomeBlock;
