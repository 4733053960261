import React, { useState } from "react";
import "./home.scss";
import HomeBlock from "./home-block/home-block";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Roadmap from "./roadmap/roadmap";
import SpecsBlock from "./specs-block/specs-block";
import GameBlock from "./game-block/game-block";

const Home = () => {
  const navigate = () => {
    window.open("https://testnet-app.sunland.finance", "_blank");
  };

  return (
    <div>
      <div className={"menu"}>
        <div className={"menu-bar"}>
          <nav className={"menu-container"}>
            <div className={"menu-item"}>
              <AnchorLink href="#block-home">Home</AnchorLink>
            </div>

            <div className={"menu-item"}>
              <AnchorLink href="#block-game">Game</AnchorLink>
            </div>

            <div className={"menu-item"}>
              <AnchorLink href="#block-lands">Lands</AnchorLink>
            </div>

            <div className={"menu-item"}>
              <AnchorLink href="#block-roadmap">Roadmap</AnchorLink>
            </div>

            <div className={"menu-item"}>
              <button onClick={() => navigate()}>Enter app</button>
            </div>
          </nav>
        </div>
      </div>

      <div className={"home-content"}>
        <section id={"block-home"}>
          <HomeBlock />
        </section>

        <section id={"block-game"}>
          <GameBlock />
        </section>

        <section id={"block-roadmap"}>
          <Roadmap />
        </section>

        <section id={"block-lands"}>
          <SpecsBlock />
        </section>
      </div>
    </div>
  );
};

export default Home;
