import React, { useState } from "react";
import "./roadmap.scss";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faUsers,
  faDollarSign,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";

const Roadmap = () => {
  return (
    <div className={"roadmap-container"}>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#0f0c29", color: "#fff" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#0f0c29",
            color: "#fff",
            fontSize: "24px",
          }}
          dateClassName={"date-label"}
          icon={<FontAwesomeIcon icon={faGlobe} />}
          position={"left"}
        >
          <h3 className="timeline-title">Phase 1 : Land Pre-Sales</h3>
          <p className={"timeline-description"}>
            Pre-sales open soon ! Buy one of <strong>177 unique</strong> lands
            as ERC721 (NFT) and become the official King. The land name and
            emblem will be per default the country name and flag which can be
            changed in the future ! Listing on Opensea !
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#0f0c29", color: "#fff" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#0f0c29",
            color: "#fff",
            fontSize: "20px",
          }}
          dateClassName={"date-label"}
          icon={<FontAwesomeIcon icon={faUsers} />}
          position={"right"}
        >
          <h3 className="timeline-title">Phase 2 : Join as citizen !</h3>
          <p className={"timeline-description"}>
            Join the territory of your choice as a game character. Make the
            right choice to become a pillar of your country and make it evolve.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#0f0c29", color: "#fff" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#0f0c29",
            color: "#fff",
            fontSize: "20px",
          }}
          dateClassName={"date-label"}
          icon={<FontAwesomeIcon icon={faCrown} />}
          position={"left"}
        >
          <h3 className="timeline-title">Phase 3 : Recruit</h3>
          <p className={"timeline-description"}>
            We will recruit an illustrator to design unique artwork for Monks,
            Farmers and Warriors . Offering free citizen spot for early adopters
            !
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#0f0c29", color: "#fff" }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#0f0c29",
            color: "#fff",
            fontSize: "43px",
          }}
          dateClassName={"date-label"}
          icon={<FontAwesomeIcon icon={faDollarSign} />}
          position={"right"}
        >
          <h3 className="timeline-title">Phase 4 : Play and Earn !</h3>
          <p className={"timeline-description"}>
            Play everyday to help your country grow, and earn tokens. Choose the
            role you will play in your country and use your power wisely. A
            country’s worth will go up or down depending on its influence and
            development.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Roadmap;
